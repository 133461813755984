.container {
    position: absolute;
    width: auto;
}

.joboffers-text {
    width: 75%;
    margin: 10vh 12.5%;
    padding: 30px;
    border-radius: 10px;
    background-color: rgba(247, 205, 91, 0.774);
    font-size: large;
    overflow: auto;
}

.logo-container {
    margin: 20vh 40vw;
    padding: 30px;
}

.joboffers-button-container {
    position: relative;
    
}

.joboffers-button {
    border-radius: 10px;
    width: 170px;
    font-size: 14px;
    letter-spacing: 0.2rem;
    padding: 5px 10px;
    border: 1px solid transparent;
    outline: none;
    transition: all 0.5s ease-in-out;
    background: #EF8B2D;
    opacity: 0.8;
}

.joboffers-button:hover {
    border: 1px solid #EF8B2D;
    background-color: rgb(199,202,199);
    cursor: pointer;
}

@media screen and (max-width: 740px) {
    .joboffers-text {
        font-size: 14px;
        margin: 45vh 7.5%;
    }
    .logo-container {
        margin: 45vh 20vw;
        padding: 30px;
    }
}