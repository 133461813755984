.servicecard-image-container {
    list-style: none;
    /* width: 100%;
    height: 100%; */
    padding: 10px;
}

.image-container {
    position: relative;
    height: 280px;
    width: inherit;
    transition: all 500ms ease-out;
    overflow: hidden;
}

.serviceimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.servicecard-image-container figure .text-container{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,103,123,0.65);
    opacity: 0;
    visibility: hidden;
    text-align: center;
}

.servicecard-image-container figure:hover .text-container {
    opacity: 1;
    visibility: visible;
}

.servicecard-image-container figure:hover .servicetitle {
    margin-top: 70px;
    margin-bottom: 15px;
}

.servicecard-image-container figure:hover img {
    transform: scale(1.3);
    transition: transform 500ms ease-out;
}

.servicetitle{
    color: #fff;
    font-size: 30px;
    font-weight: 700px;
    margin-bottom: 120px;
    margin-top: 30px;
    transition: all 500ms ease-out;
}

.servicedescription {
    color: #fff;
    font-size: 16px;
    width: 100%;

}