.slideshow {
    /* width: 80%; */
    height: 100vh;
    overflow: hidden;
    position: relative;
    
}


.slidershow-container--image  {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    opacity: 1;
}

.slidershow-text {
    background-color: rgba(233, 231, 231, 0.5);
    color: rgb(3, 3, 3);
    /* opacity: .6; */
    width: 75%;
    text-align: center;
    position: absolute;
    margin-top: -50px;
    top: 50%;
    left: 12.5%;
    font-size: 2.5rem;
    font-weight: bold;
}

.slidershow-arrow {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    width: 50%;
    text-align: center;
    position: absolute;
    margin-top: -50px;
    top: 50%;
    left: 50%;
    font-size: 2.5rem;
    font-weight: bold;
    display: block;
}


@media screen and (max-width: 750px) {
    .slidershow-container--image{
        height: 100vh;
    }
    .slidershow-text {
        font-size: 20px;
        margin: 60px 5px;
    }
}