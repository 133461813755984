.footer-container {
    color: white;
    background-color: rgba(31, 27, 27, 0.884);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Roboto', cursive;
    font-size: 12px;
    height: 150px;
}



.footer-image {
    height: 120px;
    width: 250px;
}

.footer-whatsapp-container {
    border-radius: none;
    border: none;
    outline: none;
    opacity: 1;
}

@media screen and (max-width: 400px){

    .copyright-container{
        font-size: 9px;
    }
    .footer-image{
        width: 150px;
        height: 80px;
    }
}
