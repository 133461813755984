* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(31, 27, 27, 0.884);
  background-image: URL("./images/FondoAbstracto.jpg");
  background-size: 100% 100%;
  background-attachment:  fixed;
  background-repeat: no-repeat; */
}

/* code {
  font-size: 'Roboto', sans-serif;
} */
