.sucursal-container {
    color: white;
    display: flex;
    margin: 5vh 0;
    justify-content: space-around;
}

.sucursales-text-container {
    font-size: 1.2rem;
}

.sucursales-text-container h4 {
    text-indent: 15px;
}


@media screen and (max-width: 740px){
    .sucursales-text-container{
        font-size: 15px;
    }
    img{
        width: 250px;
        height: 250px;
    }
}