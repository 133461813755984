

.comingsoon-image {
    object-fit: cover;
    align-self: center;
    filter: blur(15px);
    width: 100%;
    height: 99vh;
}
    
.comingsoon-text {
    background-color: rgba(233, 231, 231, 0.89);
    color: rgb(41, 42, 43);
    opacity: .6;
    width: 50%;
    text-align: center;
    position: absolute;
    margin-top: -50px;
    top: 50%;
    left: 25%;
    font-size: 2.5rem;
    font-weight: bold;
}