.loading-container {
  text-align: center;
}

.loading-item {
  color:white;
  font-size: xx-large;
}
.loading-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .loading-logo {
      animation: loading-logo-spin infinite 20s linear;
    }
  }

  @keyframes loading-logo-spin {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
  }