.offerspanel-container {
    position: relative;
    /* padding-top: 18vh; */
    /* background: linear-gradient(rgb(155, 221, 135), rgb(25, 87, 51)); */
}

.form-container {
    font-size: 20wp;
    margin: 0 25%;
}

h4 {
    margin-top: 10px;
}

textarea {
    padding: 10px;
    resize: none;
}

.jobofferspanel-button {
    margin: 0 auto;
    display: block;
    text-align: center;
    border-radius: 10px;
    width: 170px;
    font-size: 14px;
    letter-spacing: 0.2rem;
    padding: 5px 10px;
    border: 1px solid transparent;
    outline: none;
    transition: all 0.5s ease-in-out;
    position: relative;
    background: rgb(240, 135, 36);
    opacity: 0.8;
}

.jobofferspanel-button:hover {
    border: 1px solid rgb(190, 141, 43);
    background-color: rgb(199,202,199);
    cursor: pointer;
}

@media screen and (max-width: 740px) {
    .offerspanel-container {
        position: relative;
        /* padding-top: 35vh; */
    }
}

TextareaAutosize {
    width: 100%;
}