.classValid {
    width: 100%;
    border-radius: 3px;
    height: 30px;
    padding: 0 40px 0 10px;
    transition: .3s ease-out;
    border: 3px solid transparent;
}

.classInvalid {
    width: 100%;
    border-radius: 3px;
    height: 35px;
    padding: 0 40px 0 10px;
    transition: .3s ease-out;
    border: 3px solid red;
    outline: none;
    /* background-color: red; */

}
.name-Input:focus {
    border: 3px solid rgb(62, 97, 17);
    outline: none;

}

.Icon {
    position: absolute;
    right: 2px;
    bottom: 2px;
    z-index: 100;
    color: green;
    font-size: 16px;
    opacity: 0;
}

.input-label {
    display: block;

    font-weight: 700;
    text-align: left;
    padding: 5px;
    cursor: pointer;
}


.input-error {
    font-size: 12px;
    color: red;
    margin-bottom: 0;
    text-align: left;
    display: none;
}