.aboutus-container {
    background-color: rgba(240, 248, 255, 0.1);
    padding: 15vh 100px;
    font-size: 40px;
    /* color: rgb(194, 192, 192); */
    position: relative;
}

.image-title {
    width: 600px;
    height: 350px;
}

.title-container {
    /* background-color: rgba(177, 95, 40, 0.829); */
    height: 80px;
    font-size: 1em;
    padding-top: 25px;
    padding-left: 5px;
}


.subtitle-container {
    text-decoration: underline;
    font-size: .8em;
    height: 3em;
    padding-top: 1em;
    /* background-color: aliceblue; */
}

.paragraph-container {
    font-weight: 400;
    line-height: 2em;
    padding: 5px 30px;
    font-size: .5em;
    text-align: justify;
}

.flex-container {
    display: flex;
    justify-content:space-around;
}


@media screen and (max-width: 740px){
    .aboutus-container {
        font-size: 25px;
        padding: 1em 0;
    }
    .history-container{
        /* margin-top: 20px; */
    }
    .image-title {
        width: 150px;
        height: 150px;
        /* position: absolute; */
        right: 0;
    }
}