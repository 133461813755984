.navbar-container {
    background-color: rgb(248, 245, 245,0.4);
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-end;
    position: absolute;
    z-index: 999;
    flex-direction: row;
    /* box-shadow: 10px 10px 10px 0px rgba(243, 123, 53, 0.877); */
    box-shadow: 10px 10px 10px 0px #ef8b2d;
    top: 0;
}

.navbar-logo-container {
    margin: 15px 50px;
    height: auto;
    width: 100%;
    background-size: cover;
    
}

.navbar-logo , .navbar-logo:before , .navbar-logo:after {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.navbar-logo {
    height: 8vmin;
    pointer-events: none;
    animation-name:rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.navbar-logo:before , .navbar-logo:after{
    content:"";
}

.navbar-logo:after{
    transform:rotate(-57deg);
}

.navbar-logo:before{
    transform:rotate(57deg);
}

@keyframes rotate{

    100%{
      transform:rotate(360deg);
    }
  }

.navbar-button-container{
    height: 60px;
    width: 200px;
    background: none;
}

.navbar-button{
    border: none;
    height: 60px;
    width: 200px;
    background: none;
    color: rgb(17, 17, 17);
    font-weight: bold;
    font-family: 'Roboto', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    transition: ease-out 0.3s;
    cursor: pointer;
    font-size: 22px;
    padding: 10px;
    
}


/* .navbar-menu-container{
    position: absolute;
    right: 0;
    bottom: 25px;
} */


@media screen and (max-width: 1150px) {
    .navbar-container{
        height: 150px;
        flex-wrap: wrap;
    }
    .navbar-logo-container {
        margin: 15px 50px;
        background-size: cover;
        /*width: 70px;
        height: 70px;*/
    }

}

@media screen and (max-width: 570px){
    .navbar-container {
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        box-shadow: none;
        
    }
    .navbar-logo-container{
        height: 50px;
    }
    .navbar-logo{
        width: 50px;
        height: 50px;
    }

    .navbar-button-container{
        height: 40px;
        
    }

    .navbar-button{
        font-size: 14px;
        width: 150px;
        height: 40px;
        text-align: left;
    }

}

.navbar-button:hover{
    box-shadow: inset 1px 0px 10px 0px rgb(252, 190, 76);
    /* color: rgb(68, 109, 2);
    cursor: pointer;
    text-shadow: -5px -15px rgb(228, 153, 13);
    height: 8vh;
    width: 25vh; */
}
