
.contact-head {
    text-align: center;
    margin-top: 75px;

}

.contact-head h2 {
    display: inline;
    color: #7b2a86;
    font-family: 'Roboto', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 100px;
}


.contact-container-socialnetworks{
    display: flex;
    justify-content: center;
}

.contact-socialnetworks-icons {
    cursor: pointer;
    color: #7b2a86;
    margin-left: 15px;
}


@media screen and (max-width: 667px) {
    .contact-head {
        text-align: center;
        margin-top: 50px;
    }

    .contact-head h2 {
        display: inline;
        font-family: 'Roboto', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 50px;
    }
    .contact-socialnetworks-icons {
        font-size: 40px !important;
    }
}

@media screen and (max-width: 664px) {
    .contact-container {
        display: block;
    }
} 