.servicegrid {
    /* padding-top: 20vh; */
    /* padding-bottom: 20px; */
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 750px)); */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 300px 300px ;
    grid-column-gap: 50px;
    grid-row-gap: 10px;
}

@media screen and (max-width: 1000px) {
    .servicegrid{
        grid-template-columns: repeat(auto-fill, minmax(300px, 750px));
    }
}

@media screen and (max-width: 740px) {
    .servicegrid{
        padding-top: 5vw;
    }
}

@media screen and (max-width: 400px) {
    .servicegrid{
        padding-top: 5vw;
    }
}