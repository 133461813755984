.modal-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: all 0.3s ease-in-out;
    z-index: 999; */
}


.modal-content{
    /* position: fixed; */
    /* margin: 20vh 30%; */
    width: 800px;
    max-height: 700px;
    overflow-y: scroll;
    background-color: #EEF1FA;
    border-radius: 10px;
    /* background: linear-gradient(#f3f1f3, rgba(117, 73, 73, 0.829)); */
    /* background: linear-gradient(rgb(224, 211, 236), rgba(41, 40, 40, 0.979)); */
    box-shadow: 10px 10px 25px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 25px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 25px 4px rgba(0,0,0,0.75);
    border-top: 2px solid linear-gradient(rgb(224, 211, 236), rgba(41, 40, 40, 0.979));;
    border-bottom: 2px solid linear-gradient(rgb(224, 211, 236), rgba(41, 40, 40, 0.979));
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.modal-title {
    font-size: 16px;
    color: #000;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    outline: none;
    border-radius: 10px;
    border: transparent;
    /* color: #ffff; */
    background: #953aa1;
    transition: .3s ease all;
}

.close-button :hover {
    background: #46184D;
    border-radius: 10px;
    border: transparent;
    width: 25px;
    height: 25px;
}

.modal-body {
    padding: 10px;
    /* border-top: 2px solid #a8dafa;
    border-bottom: 2px solid #a8dafa;
    background-color: #1d3557; */
}

.modal-list {
    flex: 1;
    padding: 30px;
    border-radius: 10px;
}

.modal-list-item {
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}

.modal-visualizing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #EEF1FA;
    font-size: 18px;
}

.modal-input-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center;
    width: 100%;
}

.modal-pass {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    width: 100%;
}

.modal-footer{
    display: flex;
    justify-content: space-between;
}

.modal-button {
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    width: 170px;
    font-size: 14px;
    letter-spacing: 0.2rem;
    padding: 5px 10px;
    border: 1px solid transparent;
    outline: none;
    transition: all 0.5s ease-in-out;
    position: relative;
    background: #EF8B2D;
    opacity: 0.8;
}

.modal-button:hover {
    border: 1px solid #EF8B2D;
    background-color: rgb(199,202,199);
    cursor: pointer;
}

.modal-button-exit {
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    width: 170px;
    font-size: 14px;
    letter-spacing: 0.2rem;
    padding: 5px 10px;
    border: 1px solid transparent;
    outline: none;
    transition: all 0.5s ease-in-out;
    position: relative;
    background: #EF8B2D;
    opacity: 0.8;
}

.modal-button-exit:hover {
    border: 1px solid #EF8B2D;
    background-color: rgb(199,202,199);
    cursor: pointer;
}

@media screen  and (max-width: 1150px) {
    .modal-content{
        margin: 0;
        padding: 0 5px;
        top: 10vh;
        max-height: 700px;

    }
}

@media screen  and (max-width: 750px) {
    .modal-content{
        margin: 0;
        padding: 0 5px;
        top: 10vh;
        max-height: 400px;
        max-width: 350px;
    }
}